<template>
  <page-template>
    <main-block>
      <div class="content-page wide-md m-auto">
        <div class="bg-white bg-white p-4 rounded mb-3 d-flex justify-between" v-if="!$isHybridPlatform">
          <brand-logo></brand-logo>
          <nk-button type="primary" v-if="ionRouter.canGoBack()" v-on:click="$router.go(-1)">{{ $t('Back') }}</nk-button>
          <nk-button type="primary" v-else v-on:click="$router.push({name: 'Home'})">{{ $t('Home') }}</nk-button>
        </div>
        <div class="nk-block-head nk-block-head-lg wide-sm mx-auto">
          <div class="nk-block-head-content text-center">
            <h2 class="nk-block-title fw-normal">Privacybeleid</h2>
            <div class="nk-block-des">
              <div class="lead text-left">
                <p>Hypotheekio, gevestigd aan Bijsterhuizen 2122, 6604LG Wijchen, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
                <p>Contactgegevens:</p>
                <p><a href="https://dashboard.hypotheek.io">https://dashboard.hypotheek.io</a></p>
                <p>Bijsterhuizen 2122, 6604LG Wijchen</p>
                <p>0636255807</p>
                <p>Michael Kwasiuk is de Functionaris Gegevensbescherming van Hypotheekio Hij/zij is te bereiken via <a href="mailto:michael@webstrategen.nl">michael@webstrategen.nl</a></p>
              </div>
            </div>
          </div>
        </div>
        <block-content>
          <div class="card">
            <div class="card-inner card-inner-xl">
              <div class="entry">
                <h3>Data die wij verzamelen van gebruikers</h3>
                <ul style="list-style: inside;">
                  <li>Voor- en achternaam</li>
                  <li>Adresgegevens</li>
                  <li>Telefoonnummer</li>
                  <li>E-mailadres</li>
                  <li>IP-adres</li>
                  <li>Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
                  <li>Locatiegegevens</li>
                  <li>Gegevens over jouw activiteiten op onze website</li>
                  <li>Lijst met contactgegevens van de klant via een app</li>
                  <li>Internetbrowser en apparaat type</li>
                </ul>
                <h3>Persoonsgegevens die wij verwerken</h3>
                <p>Hypotheekio verwerkt geen persoonsgegevens omdat op onze site geen persoonsgegevens achter gelaten kunnen worden. Ook gebruiken we geen social media plugins.</p>
                <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
                <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@hypotheek.io, dan verwijderen wij deze informatie.</p>
                <h3>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h3>
                <p>Hypotheekio verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
                <ul style="list-style: inside;">
                  <li>Het afhandelen van jouw betaling</li>
                  <li>Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                  <li>Je te informeren over wijzigingen van onze diensten en producten</li>
                  <li>Je de mogelijkheid te bieden een account aan te maken</li>
                  <li>Om goederen en diensten bij je af te leveren</li>
                  <li>Hypotheekio verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte.</li>
                </ul>
                <h3>Geautomatiseerde besluitvorming</h3>
                <p>Hypotheekio neemt op basis van geautomatiseerde verwerkingen besluiten over zaken die mogelijk gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Hypotheekio) tussen zit. Zo maken wij gebruik van algoritmes die op basis van datapunten besluiten maken bij het doorzetten van leads.</p>
                <h3>Hoe lang we persoonsgegevens bewaren</h3>
                <p>Hypotheekio bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:
                  - Tot 2 jaar na afnemen van onze diensten.</p>
                <h3>Delen van persoonsgegevens met derden</h3>
                <p>Hypotheekio verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Hypotheekio blijft verantwoordelijk voor deze verwerkingen.</p>
                <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
                <p>Hypotheekio gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Zoals bijvoorbeeld ingelogd blijven op je account.
                  Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p>
                <h3>Gegevens inzien, aanpassen of verwijderen</h3>
                <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Hypotheekio en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar info@hypotheek.io. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek .</p>
                <h3>Hoe wij persoonsgegevens beveiligen</h3>
                <p>Hypotheekio neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via info@hypotheek.io</p>
              </div>
            </div>
          </div>
        </block-content>
      </div>
    </main-block>
  </page-template>
</template>

<script>
import {useIonRouter} from "@ionic/vue"
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import BrandLogo from "@core/components/logo/BrandLogo";
import NkButton from "@core/components/button/NkButton";
import PageTemplate from "@core/layouts/page/PageTemplate";
export default {
  name: "TOS",
  components:{PageTemplate, NkButton, BrandLogo, BlockContent, MainBlock},
  setup(){
    const ionRouter = useIonRouter()
    return{
      ionRouter,
    }
  }
}
</script>

<style scoped>

</style>